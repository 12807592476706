<template>
  <div class="mainpage">
    <div class="head">
      <div class="head-title">我的请假</div>
      <div class="retutnbt" @click="toback">返回</div>
    </div>
    <div class="line"></div>
    <div class="maincontain">
      <div class="searchcontain">
        <div class="left">
          <div class="contain">
            <div class="maintext">审核状态:</div>
            <el-select
              class="elinput"
              v-model="queryParams.status"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>

          <div class="contain">
            <div class="maintext">考勤日期:</div>
            <el-date-picker
              v-model="timevalue"
              type="datetimerange"
              range-separator="--"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </div>
        </div>
        <div class="right">
          <el-button type="primary" @click="search" style="background: #3d84ff"
            >查询</el-button
          >
          <el-button type="primary" @click="newadd" style="background: #3d84ff"
            >新增</el-button
          >
          <el-button type="info" @click="reset">重置</el-button>
          <!-- <el-button class="bt searchbt" @click="search">查询</el-button>
          <el-button class="bt restbt" @click="reset">重置</el-button>
          <el-button class="bt" @click="newadd">新增</el-button>-->
        </div>
      </div>
      <div class="main-contain">
        <div class="contain">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            class="Table"
            :header-cell-style="{
              'font-size': '15px',
              color: '#666666',
              'font-weight': 'bold',
              background: '#F7F7F7',
            }"
            :row-style="{
              'font-size': '15px',
              color: '#222222',
              'font-weight': '400',
            }"
          >
            <el-table-column prop="created_at" label="申请日期">
              <template slot-scope="scope">{{
                scope.row.created_at | dataformat
              }}</template>
            </el-table-column>
            <el-table-column label="请假时间">
              <template slot-scope="scope">
                {{ scope.row.start_time | dataformat }} ~
                {{ scope.row.end_time | dataformat }}
              </template>
            </el-table-column>
            <el-table-column prop="status" label="审核状态">
              <template slot-scope="scope">{{
                scope.row.status | statusformat
              }}</template>
            </el-table-column>

            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <div class="item-right">
                  <div class="bt" @click="checkbt(scope.row)">查看</div>
                  <div class="line2"></div>
                  <div class="bt" @click="revisebt(scope.row)">修改</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="elpagination">
            <div class="elpagination-title">共{{ total }}条</div>
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              :page-size="queryParams.per_page"
              :current-page="queryParams.page"
              @current-change="pageChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <Customdialog
      class="customdialog"
      ref="customdialog"
      type="min"
      width="700px"
      title="请假查看"
      :showclose="true"
    >
      <div slot="dialogbody" class="dialogbody" v-if="infoData">
        <div class="text">实习计划： {{ infoData.plan_name }}</div>
        <div class="text">类型： {{ infoData.status | statusformat }}</div>
        <div class="text">
          时间： {{ infoData.start_time | dataformat }} ~
          {{ infoData.end_time | dataformat }}
        </div>
        <div class="text">原因： {{ infoData.reason }}</div>
        <div class="downbt" v-for="(item, index) in infoData.file" :key="index">
          <div class="text">附件：{{ item.name }}</div>
          <!-- <el-image class="elimage" :src="icon3" /> -->
          <el-image class="elimage" :src="icon4" @click="download(item)" />
        </div>
      </div>
      <div slot="dialogfooter" class="dialogfooter"></div>
    </Customdialog>
  </div>
</template>

<script>
const statusmap = ["待审核", "已驳回", "已同意"];
import Customdialog from "@/components/customdialog.vue";
import dayjs from "dayjs";
import {
  getpracticeleavelist,
  getpracticeleaveinfo,
} from "@/api/practiceleave";
export default {
  name: "mainpage",
  components: {
    Customdialog,
  },
  data() {
    return {
      // 查询参数
      queryParams: {
        practice_plan_id: "",
        star_time: "",
        end_time: "",
        status: "",
        page: 1,
        per_page: 10,
      },
      tableData: [], // 表格数据
      total: 0, // 总页数
      options: [
        { value: 0, label: "待审核" },
        { value: 1, label: "已驳回" },
        { value: 2, label: "已同意" },
      ],
      timevalue: "", // 时间段
      infoData: {},
      icon3: require("@/assets/查看.png"),
      icon4: require("@/assets/下载.png"),
    };
  },
  computed: {
    plan_id() {
      return this.$route.query.plan_id;
    },
  },
  created() {
    this.queryParams.practice_plan_id = this.plan_id;
    this.getpracticeleavelist();
  },
  filters: {
    statusformat(val) {
      return statusmap[val];
    },
    dataformat(value) {
      if (!value) return "";
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  methods: {
    // 获取列表数据
    getpracticeleavelist() {
      this.tableData = []; // 清空数据
      let params = this.queryParams;
      getpracticeleavelist(params)
        .then((res) => {
          if (res.code === 0 && res.data) {
            this.tableData = res.data.data;
            this.total = res.data.total;
          } else {
            //console.log(res.message);
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    // 分页切换
    pageChange(val) {
      this.queryParams.page = val;
      this.getpracticeleavelist();
    },
    // 重置
    reset() {
      this.queryParams.star_time = "";
      this.queryParams.end_time = "";
      this.timevalue = "";
      this.queryParams.status = "";
      this.getpracticeleavelist();
    },
    // 查询
    search() {
      if (this.timevalue && this.timevalue.length > 0) {
        if (this.timevalue[0]) {
          this.queryParams.star_time = dayjs(this.timevalue[0]).unix();
        }
        if (this.timevalue[1]) {
          this.queryParams.end_time = dayjs(this.timevalue[1]).unix();
        }
      } else {
        this.queryParams.star_time = "";
        this.queryParams.end_time = "";
      }

      this.getpracticeleavelist();
    },
    // 添加
    newadd() {
      this.$router.push({
        path: "/home/personalcenter/newleave",
        query: {
          plan_id: this.plan_id,
        },
      });
    },
    // 返回
    toback() {
      this.$router.push({
        path: "/home/personalcenter/mainpage",
        query: {
          id: 6,
          componentId: "Myinternship",
        },
      });
    },
    // 查看
    checkbt(item) {
      this.getpracticeleaveinfo({ id: item.id });
      this.$refs.customdialog.dialogopenbt();
    },
    // 详情
    getpracticeleaveinfo(params) {
      this.infoData = [];
      getpracticeleaveinfo(params)
        .then((response) => {
          if (response.code === 0) {
            this.infoData = response.data;
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    // 修改
    revisebt(item) {
      this.$router.push({
        path: "/home/personalcenter/newleave",
        query: {
          id: item.id,
          type: "edit",
          plan_id: this.plan_id,
        },
      });
    },
    // 下载
    download(item) {
      var ele = document.createElement("a"); // 创建下载链接
      ele.download = item.name; //设置下载的名称
      ele.style.display = "none"; // 隐藏的可下载链接
      ele.href = item.url;
      // 绑定点击时间
      document.body.appendChild(ele);
      ele.click();
      // 然后移除
      document.body.removeChild(ele);
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 640px) {
  .mainpage {
    min-height: calc(100vh - 240px);
    background: #fff;
    .head {
      margin-top: 66px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 20px;
      padding-top: 10px;
      .head-title {
        font-size: 20px;
        font-weight: 400;
        color: #3d84ff;
        user-select: none;
        white-space: nowrap;
      }
      .retutnbt {
        margin-right: 10px;
        width: 64px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: #3d84ff;
        border-radius: 4px;
        color: white;
        user-select: none;
        cursor: pointer;
        font-size: 14px;
      }
    }
    .line {
      margin-top: 14px;
      margin-bottom: 4px;
      border-bottom: 2px solid #cccccc;
    }
    .maincontain {
      border-radius: 4px;
      // padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;

      .searchcontain {
        margin-top: 20px;
        // height: 56px;
        background: #fcfcfc;
        border: 1px solid #ececec;
        padding: 10px;
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        .left {
          // display: flex;
          // justify-content: flex-start;
          // align-items: center;
          .contain {
            // margin-right: 20px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 10px;
            .maintext {
              // margin-right: 10px;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #888888;
              width: 30%;
            }
            .elinput {
              width: 100%;
              ::v-deep .el-input__suffix {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
        .right {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .bt {
            width: 64px;
            height: 38px;
            line-height: 38px;
            text-align: center;
            background: #3d84ff;
            color: white;
            padding: 0px;
          }
          .restbt {
            margin-right: 10px;
            background: white;
            color: #3d84ff;
          }
        }
      }
      .main-contain {
        margin-top: 20px;
        width: 100%;
        .contain {
          width: 100%;
          height: 100%;
          .Table {
            width: 100%;
            .item-left {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              .title {
                margin-left: 10px;
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #222222;
              }
            }
            .item-middle {
              .title {
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #222222;
              }
            }

            .item-right {
              display: flex;
              justify-content: center;
              align-items: center;
              .bt {
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #0773fc;
                user-select: none;
                cursor: pointer;
              }
              .line2 {
                margin-right: 8px;
                margin-left: 8px;
                height: 14px;
                border-right: 2px solid #cccccc;
              }
            }
          }
          .elpagination {
            margin-top: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            .elpagination-title {
              margin-right: 4px;
            }
          }
        }
      }
    }
    .customdialog {
      .dialogbody {
        padding: 40px;
        .text {
          font-size: 16px;
          font-weight: 400;
          line-height: 30px;
          color: #222222;
        }
        .downbt {
          margin-top: 30px;
          // margin-left: 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .elimage {
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1200) {
  .mainpage {
    min-height: calc(100vh - 240px);
    background: #fff;
    .head {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 20px;
      padding-top: 10px;
      .head-title {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #3d84ff;
        user-select: none;
        white-space: nowrap;
      }
      .retutnbt {
        margin-right: 40px;
        width: 64px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: #3d84ff;
        border-radius: 4px;
        color: white;
        user-select: none;
        cursor: pointer;
        font-size: 14px;
      }
    }
    .line {
      margin-top: 14px;
      margin-bottom: 4px;
      border-bottom: 2px solid #cccccc;
    }
    .maincontain {
      border-radius: 4px;
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;

      .searchcontain {
        margin-top: 20px;
        height: 56px;
        background: #fcfcfc;
        border: 1px solid #ececec;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        .left {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .contain {
            margin-right: 20px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .maintext {
              margin-right: 10px;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #888888;
            }
            .elinput {
              width: 200px;
              ::v-deep .el-input__suffix {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
        .right {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .bt {
            width: 64px;
            height: 38px;
            line-height: 38px;
            text-align: center;
            background: #3d84ff;
            color: white;
            padding: 0px;
          }
          .restbt {
            margin-right: 10px;
            background: white;
            color: #3d84ff;
          }
        }
      }
      .main-contain {
        margin-top: 20px;
        width: 100%;
        .contain {
          width: 100%;
          height: 100%;
          .Table {
            width: 100%;
            .item-left {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              .title {
                margin-left: 10px;
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #222222;
              }
            }
            .item-middle {
              .title {
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #222222;
              }
            }

            .item-right {
              display: flex;
              justify-content: center;
              align-items: center;
              .bt {
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #0773fc;
                user-select: none;
                cursor: pointer;
              }
              .line2 {
                margin-right: 8px;
                margin-left: 8px;
                height: 14px;
                border-right: 2px solid #cccccc;
              }
            }
          }
          .elpagination {
            margin-top: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            .elpagination-title {
              margin-right: 4px;
            }
          }
        }
      }
    }
    .customdialog {
      .dialogbody {
        padding: 40px;
        .text {
          font-size: 16px;
          font-weight: 400;
          line-height: 30px;
          color: #222222;
        }
        .downbt {
          margin-top: 30px;
          // margin-left: 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .elimage {
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
